import { createAsyncThunk } from '@reduxjs/toolkit'
import flow from 'lodash/fp/flow'

import type { RootState, ThunkDispatchType } from '../../../../app/store'
import { deskApi } from '../../../deskApi'
import { MessageKey } from './slice'

export const initMessage = createAsyncThunk<
  { key: MessageKey; message: string },
  { key: MessageKey; message: string },
  {
    state: RootState
  }
>(
  'requesterTicketList/requesterChatText/initMessage',
  async (args, thunkAPI) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(args.message, 'text/html')

    const replaceImagesIntoGraphThumbnail =
      replaceImagesIntoGraphThumbnailCreator(thunkAPI.dispatch)
    const result = await replaceImagesIntoGraphThumbnail(doc)

    return {
      key: args.key,
      message: flow(
        wrapImageWithLink,
        textLinkToAnchor,
        (d) => d.body.innerHTML
      )(result),
    }
  }
)

const replaceImagesIntoGraphThumbnailCreator =
  (dispatch: ThunkDispatchType) =>
  async (doc: Document): Promise<Document> => {
    const images = doc.querySelectorAll('img')

    await Promise.all(
      Array.from(images).map(async (image) => {
        image.setAttribute('style', 'height: auto; max-width: 25rem;')

        if (isUploadedByRequester(image)) {
          const thumbnail = await getImageThumbnailUploadedByRequester(
            dispatch,
            image
          )
          image.setAttribute('src', thumbnail)
          return image
        }

        if (isUploadedByOperator(image)) {
          const thumbnail = await getImageThumbnailUploadedByOperator(
            dispatch,
            image
          )
          image.setAttribute('src', thumbnail)
          return image
        }
      })
    )

    return doc
  }

const isUploadedByRequester = (image: HTMLImageElement): boolean => {
  const driveId = image.getAttribute('data-drive-id')
  if (!driveId) return false
  const itemId = image.getAttribute('data-item-id')
  if (!itemId) return false
  return true
}

const isUploadedByOperator = (image: HTMLImageElement): boolean => {
  const src = image.getAttribute('src')
  if (!src) return false
  if (src.indexOf('group_id=') === -1) return false
  if (src.indexOf('item_id=') === -1) return false
  return true
}

const getImageThumbnailUploadedByRequester = async (
  dispatch: ThunkDispatchType,
  image: HTMLImageElement
): Promise<string> => {
  const driveId = image.getAttribute('data-drive-id')
  if (!driveId) return ''
  const itemId = image.getAttribute('data-item-id')
  if (!itemId) return ''

  const res = await dispatch(
    deskApi.endpoints.getImageThumbnail.initiate(
      {
        driveId,
        itemId,
      },
      { track: false }
    )
  ).unwrap()
  return res.c10000x10000
}

const getImageThumbnailUploadedByOperator = async (
  dispatch: ThunkDispatchType,
  image: HTMLImageElement
): Promise<string> => {
  const src = image.getAttribute('src')
  if (!src) return ''
  const url = new URL(src)
  const searchParams = new URLSearchParams(url.search)

  const groupId = searchParams.get('group_id')
  if (!groupId) return ''
  const itemId = searchParams.get('item_id')
  if (!itemId) return ''

  const res = await dispatch(
    deskApi.endpoints.getImageThumbnail.initiate(
      {
        groupId,
        itemId,
      },
      { track: false }
    )
  ).unwrap()
  return res.c10000x10000
}

const wrapImageWithLink = (doc: Document): Document => {
  const images = doc.querySelectorAll('img')

  Array.from(images).forEach((image) => {
    const link = doc.createElement('a')
    link.setAttribute('href', image.getAttribute('src') ?? '')
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    image.parentNode?.insertBefore(link, image)
    link.appendChild(image)
  })

  return doc
}

export const textLinkToAnchor = (doc: Document): Document => {
  const linkRegex =
    /((https?:\/\/)(([a-z0-9][a-z0-9-]*\.)+[a-z0-9][a-z0-9-]*|localhost)(:\d+)?(\/[^\s]*)?)/gi

  const createAnchor = (match: RegExpMatchArray, doc: Document) => {
    const anchor = doc.createElement('a')
    const url = match[1].startsWith('http') ? match[1] : `http://${match[1]}`
    anchor.href = url
    anchor.textContent = match[1]
    anchor.target = '_blank'
    anchor.rel = 'noopener noreferrer'
    return anchor
  }

  const walkTree = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textNode = node as Text
      const textContent = textNode.textContent || ''
      const matches = Array.from(textContent.matchAll(linkRegex))
      if (matches.length > 0) {
        const fragment = doc.createDocumentFragment()
        let lastIndex = 0

        matches.forEach((match) => {
          const linkStartIndex = match.index || 0
          const beforeLinkText = textContent.slice(lastIndex, linkStartIndex)
          lastIndex = linkStartIndex + match[0].length
          fragment.append(
            doc.createTextNode(beforeLinkText),
            createAnchor(match, doc)
          )
        })

        fragment.appendChild(doc.createTextNode(textContent.slice(lastIndex)))
        textNode.replaceWith(fragment)
      }
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      (node as HTMLElement).tagName !== 'A'
    ) {
      Array.from(node.childNodes).forEach(walkTree)
    }
  }

  walkTree(doc.body)
  return doc
}
